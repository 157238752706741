<template>
    <div class="admin-index">
        <navigationBar @buttonClickEmit0="addAdmin" :buttonList="buttonList" />

        <div>
            <!--搜索区-->
            <div>
                <el-form :inline="true" :model="filters" ref="filters">
                    <el-form-item prop="name" label="标题:">
                        <el-input
                            v-model="filters.title"
                            placeholder="请输入标题"
                            size="mini"
                        ></el-input>
                    </el-form-item>

                    <el-form-item prop="deptId" label="工单类型">
                        <template>
                            <el-select
                                v-model="filters.type"
                                placeholder="请选择"
                                size="mini"
                                clearable
                            >
                                <el-option
                                    v-for="item in org_type_list"
                                    :key="item.dict_key"
                                    :label="item.dict_value"
                                    :value="item.dict_key"
                                ></el-option>
                            </el-select>
                        </template>
                    </el-form-item>

                    <el-form-item prop="level" label="紧急级别">
                        <template>
                            <el-select
                                v-model="filters.level"
                                placeholder="请选择"
                                size="mini"
                                clearable
                            >
                                <el-option
                                    v-for="item in work_level_list"
                                    :key="item.dict_key"
                                    :label="item.dict_value"
                                    :value="item.dict_key"
                                ></el-option>
                            </el-select>
                        </template>
                    </el-form-item>

                    <el-form-item prop="user_name" label="发起人:">
                        <el-input
                            v-model="filters.user_name"
                            placeholder="请输入发起人"
                            size="mini"
                        ></el-input>
                    </el-form-item>

                    <el-form-item prop="status" label="工单状态:">
                        <el-select
                            v-model="filters.status"
                            placeholder="请选择"
                            size="mini"
                            clearable
                        >
                            <el-option
                                v-for="item in work_status_list"
                                :key="item.dict_key"
                                :label="item.dict_value"
                                :value="item.dict_key"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button
                            type="primary"
                            @click="findAdminPage()"
                            size="mini"
                            >查询</el-button
                        >
                    </el-form-item>
                    <el-form-item style="margin-left: 0rem">
                        <el-button type="info" size="mini" @click="resetForm()"
                            >重置</el-button
                        >
                    </el-form-item>
                    <span class="add-button" style="margin-top: 5px"></span>
                </el-form>
            </div>

            <!--列表区-->
            <el-table
                :data="workOderList"
                border
                highlight-current-row
                :cell-style="{ padding: '5px' }"
                v-loading="listLoading"
                style="width: 100%"
            >
                <el-table-column
                    prop="idNo"
                    label="序号"
                    width="80px"
                ></el-table-column>
                <el-table-column prop="title" label="标题"></el-table-column>
                <el-table-column
                    prop="user_name"
                    label="发起人"
                ></el-table-column>
                <el-table-column
                    prop="handMen_name"
                    label="处理人"
                ></el-table-column>
                <el-table-column
                    prop="workType_str"
                    label="工单类型"
                ></el-table-column>
                <el-table-column prop="work_level_str" label="紧急级别">
                    <template scope="scope">
                        <p
                            style="color: #ff0000"
                            v-if="scope.row.work_level_str == '普通'"
                        >
                            {{ scope.row.work_level_str }}
                        </p>
                        <p
                            style="color: #67c23a"
                            v-if="scope.row.work_level_str == '特急'"
                        >
                            {{ scope.row.work_level_str }}
                        </p>
                        <p
                            style="color: #e6a23c"
                            v-if="scope.row.work_level_str == '加急'"
                        >
                            {{ scope.row.work_level_str }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column prop="work_status_str" label="工单状态">
                    <template scope="scope">
                        <p
                            style="color: #ff0000"
                            v-if="scope.row.work_status_str == '待处理'"
                        >
                            {{ scope.row.work_status_str }}
                        </p>
                        <p
                            style="color: #67c23a"
                            v-if="scope.row.work_status_str == '已完结'"
                        >
                            {{ scope.row.work_status_str }}
                        </p>
                        <p
                            style="color: #e6a23c"
                            v-if="scope.row.work_status_str == '转发中'"
                        >
                            {{ scope.row.work_status_str }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="create_time"
                    label="创建时间"
                    width="210"
                ></el-table-column>

                <el-table-column label="操作" width="250">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="toWorkOrderInfo(scope.row.id)"
                            type="success"
                            >详情</el-button
                        >
                        <el-button
                            size="mini"
                            @click="updateWorkOrder(scope.row)"
                            type="warning"
                            >修改</el-button
                        >
                        <el-button
                            size="mini"
                            @click="delWorkOrder(scope.row.id)"
                            type="danger"
                            >删除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 分页 -->
        <div class="Pagination" style="text-align: right; margin-top: 10px">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="pageNo"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="count"
            ></el-pagination>
        </div>

        <!--新建工单弹窗-->
        <el-dialog
            :title="titleAdmin"
            :visible.sync="dialogFormVisible"
            width="40%"
        >
            <el-form ref="form" label-width="15%" :rules="rules" :model="form">
                <el-form-item label="标  题" prop="title">
                    <el-input
                        type="text"
                        v-model="form.title"
                        placeholder="请输入标题"
                        style="width: 80%"
                    ></el-input>
                </el-form-item>

                <el-form-item label="内  容" prop="content">
                    <el-input
                        type="textarea"
                        v-model="form.content"
                        placeholder="请输入工单内容"
                        style="width: 80%"
                    ></el-input>
                </el-form-item>

                <el-form-item label="工单类型" prop="content">
                    <template v-for="item in org_type_list">
                        <el-radio v-model="form.type" :label="item.dict_key">{{
                            item.dict_value
                        }}</el-radio>
                    </template>
                </el-form-item>

                <el-form-item label="紧急级别">
                    <template v-for="item in work_level_list">
                        <el-radio v-model="form.level" :label="item.dict_key">{{
                            item.dict_value
                        }}</el-radio>
                    </template>
                </el-form-item>

                <el-form-item label="部  门" prop="deptId">
                    <div class="block">
                        <el-cascader
                            ref="cascader"
                            v-model="form.deptId"
                            :options="deptList"
                            :props="{ checkStrictly: true }"
                            @change="change"
                            style="width: 80%"
                            placeholder="请输入部门"
                            clearable
                        ></el-cascader>
                    </div>
                </el-form-item>

                <el-form-item label="处理人" prop="handId">
                    <template>
                        <el-select
                            v-model="form.handId"
                            filterable
                            placeholder="请选择处理人"
                            style="width: 79%"
                        >
                            <el-option
                                v-for="item in adminList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </template>
                </el-form-item>

                <el-form-item label="上传图片" prop="content">
                    <el-upload
                        class="upload-demo"
                        :action="uploadUrl"
                        :data="uploadDate"
                        :headers="uploadHeader"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :on-success="handleImageSuccess"
                        :on-error="uploadError"
                        :file-list="fileList"
                        :limit="max_num"
                        list-type="picture-card"
                    >
                        <el-button size="mini" type="primary"
                            >点击上传</el-button
                        >
                        <div slot="tip" class="el-upload__tip">
                            只能上传jpg/png文件，且不超过500kb
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" type="primary" @click="addAdmin('form')"
                    >提 交</el-button
                >
                <el-button size="mini" @click="dialogFormVisible = false"
                    >取 消</el-button
                >
            </div>
        </el-dialog>

        <!--修改工单弹窗-->
        <el-dialog
            :title="titleAdmin"
            :visible.sync="dialogFormUpdate"
            width="40%"
        >
            <el-form ref="form" label-width="15%" :rules="rules" :model="form">
                <el-form-item label="标  题" prop="title">
                    <el-input
                        type="text"
                        v-model="form.title"
                        placeholder="请输入标题"
                        style="width: 80%"
                    ></el-input>
                </el-form-item>

                <el-form-item label="内  容" prop="content">
                    <el-input
                        type="textarea"
                        v-model="form.content"
                        placeholder="请输入工单内容"
                        style="width: 80%"
                    ></el-input>
                </el-form-item>

                <el-form-item label="工单类型" prop="content">
                    <template v-for="item in org_type_list">
                        <el-radio v-model="form.type" :label="item.dict_key">{{
                            item.dict_value
                        }}</el-radio>
                    </template>
                </el-form-item>

                <el-form-item label="紧急级别" prop="content">
                    <!-- <template>
                    <el-radio v-model="form.level" label=1>普通</el-radio>
                    <el-radio v-model="form.level" label=2>加急</el-radio>
                    <el-radio v-model="form.level" label=3>特急</el-radio>
                    </template>-->
                    <template v-for="item in work_level_list">
                        <el-radio v-model="form.level" :label="item.dict_key">{{
                            item.dict_value
                        }}</el-radio>
                    </template>
                </el-form-item>

                <el-form-item label="部  门" prop="deptId">
                    <div class="block">
                        <el-cascader
                            ref="cascader"
                            v-model="form.deptId"
                            :options="deptList"
                            :props="{ checkStrictly: true }"
                            @change="change"
                            style="width: 80%"
                            placeholder="请输入部门"
                            clearable
                        ></el-cascader>
                    </div>
                </el-form-item>

                <el-form-item label="处理人" prop="handId">
                    <template>
                        <el-select
                            v-model="form.handId"
                            filterable
                            placeholder="请先选择部门"
                            style="width: 79%"
                        >
                            <el-option
                                v-for="item in adminList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </template>
                </el-form-item>

                <el-form-item label="上传图片" prop="content">
                    <el-upload
                        class="upload-demo"
                        :action="uploadUrl"
                        :data="uploadDate"
                        :headers="uploadHeader"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :on-success="handleImageSuccess"
                        :on-error="uploadError"
                        :file-list="fileList"
                        :limit="max_num"
                        list-type="picture-card"
                    >
                        <el-button size="mini" type="primary"
                            >点击上传</el-button
                        >
                        <div slot="tip" class="el-upload__tip">
                            只能上传jpg/png文件，且不超过500kb
                        </div>
                    </el-upload>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button
                    size="mini"
                    type="primary"
                    @click="updateSubmit('form')"
                    >提 交</el-button
                >
                <el-button size="mini" @click="dialogFormUpdate = false"
                    >取 消</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    findAllWorkOderPage,
    fileUpload,
    updateWorkOrder,
    findWorkRecordByhandId,
    delWorkOrder,
} from "@/api/getBaseData";
import { addWorkOder, getSysDictionaryList } from "@/api/getBaseData";
import { getDeptInfoList, getAdminListByDeptId } from "@/api/getUcenterData";
import cookies from "@/utils/cookies/index.js";
import navigationBar from "@/components/navigationBar";
import setting from "@/utils/config/index.js";
export default {
    data() {
        return {
            //工单列表
            workOderList: [],
            pageSize: 10,
            count: 0,
            pageNo: 1,
            dataEntityList: [],

            // 工单紧急级别字典表
            work_level_list: [],

            // 工单状态字典表
            work_status_list: [],

            // 标签页选中的参数
            activeName: "",

            // 最大上传数量
            max_num: 3,

            // 待我处理数量
            my_hand_total: "",
            // 我转发的数量
            my_forword_total: "",

            type_title: "",
            // 请求数据等待中标识
            listLoading: false,

            // 工单类型列表
            org_type_list: [],

            fileList: [],

            //新增工单的弹出框
            dialogFormVisible: false,
            // 修改表单弹出框
            dialogFormUpdate: false,

            titleAdmin: "",

            rules: {
                title: [
                    { required: true, message: "请输入标题", trigger: "blur" },
                ],
                deptId: [
                    { required: true, message: "请选择部门", trigger: "blur" },
                ],
                handId: [
                    {
                        required: true,
                        message: "请选择处理人",
                        trigger: "blur",
                    },
                ],
                content: [
                    { required: true, message: "请输入内容", trigger: "blur" },
                ],
            },

            //表单列
            form: {
                id: "",
                title: "",
                userId: "",
                content: "",
                departId: "",
                type: "1",
                level: "1",
                // 处理人id
                handId: "",
                imgList: [],
            },

            // 搜索框
            filters: {
                name: "",
                title: "",
                deptId: "",
                type: "",
                level: "",
                user_name: "",
                status: "",
            },
            //页头框中的按钮组
            buttonList: [
                {
                    backgroundColor: "#52D1AF",
                    icon: "/static/img/add.png",
                    text: "添加工单",
                },
            ],
            // 部门列表
            deptList: [],
            // 人员列表
            adminList: [],

            // 文件上传的地址
            // uploadUrl:setting.url.ossPath+'uploadFile',
            uploadUrl: "http://114.55.126.232:7011/oss/uploadFile",
            // 文件上传头部
            uploadHeader: {
                content_type: "application/x-www-form-urlencoded",
            },
            // 文件上传参数
            uploadDate: {
                bucketName: "dh-order",
                fileName: "dh-order",
            },
            // 图片路径
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
        };
    },

    activated() {
        let uid = cookies.get("uid");
        this.user = JSON.parse(localStorage.getItem("userInfo" + uid));
    },
    mounted() {
        this.getDeptInfoList();
        this.doQuery();
        this.findAdminPage();
        this.getWorkLevelDic();
        this.getWorkStatusDic();
    },
    components: {
        navigationBar,
    },
    methods: {
        doQuery() {
            this.listLoading = true;
            let uid = cookies.get("uid");
            this.findAdminPage();
            this.getorgTypeDic();
        },
        handleCurrentChange: function (pageNo) {
            this.pageNo = pageNo;
            this.findAdminPage();
        },
        //列表或查询
        findAdminPage: function () {
            this.listLoading = true;
            let para = {
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                type: this.filters.type,
                title: this.filters.title,
                level: this.filters.level,
                user_name: this.filters.user_name,
                status: this.filters.status,
            };
            findAllWorkOderPage(para).then(
                (resole) => {
                    var res = resole.data;
                    this.workOderList = res.list;
                    console.log("工单列表，", this.workOderList);
                    this.count = res.total;
                    for (var i = 0; i < this.workOderList.length; i++) {
                        this.workOderList[i].idNo =
                            (this.pageNo - 1) * this.pageSize + i + 1;
                    }
                    this.listLoading = false;
                },
                (err) => {
                    this.$message({
                        message: "数据请求失败",
                        type: "error",
                    });
                }
            );
        },
        // 提交添加工单
        addAdmin: function (form) {
            this.titleAdmin = "新增";
            this.dialogFormVisible = true;
            let uid = cookies.get("uid");
            let user = JSON.parse(localStorage.getItem("userInfo" + uid));

            console.log("图片列表", this.form.imgList);

            if (form) {
                this.$confirm("确认提交吗？", "提示", {}).then(() => {
                    let imglist = this.form.imgList;
                    let imgString = imglist.toString();

                    let para = {
                        title: this.form.title,
                        content: this.form.content,
                        type: this.form.type,
                        user_id: uid,
                        user_name: user.name,
                        level: this.form.level,
                        dept_id: this.form.departId,
                        hand_id: this.form.handId,
                        imgList: imgString,
                    };

                    addWorkOder(para).then((res) => {
                        this.$message({
                            message: "添加成功！",
                            type: "success",
                        });
                        this.dialogFormVisible = false;
                    });
                });
            }
        },
        // 修改按钮触发函数
        updateWorkOrder: function (data) {
            this.titleAdmin = "修改";
            data.workRecordId;
            this.dialogFormUpdate = true;
            let para = {
                id: data.id,
            };
            findAllWorkOderPage(para).then((resole) => {
                var workerOrder = resole.data.list[0];
                this.form.title = workerOrder.title;
                this.form.content = workerOrder.content;
                this.form.type = workerOrder.type;
                this.form.level = workerOrder.level;
                this.form.id = workerOrder.id;
                let imgurl = workerOrder.img_url;
                if (imgurl != null && imgurl != "") {
                    let img_array = imgurl.split(",");
                    let img_array2 = [];
                    for (let i = 0; i < img_array.length; i++) {
                        let a = {};
                        a.name = "";
                        a.url = img_array[i];
                        this.form.imgList.push(img_array[i]);

                        img_array2.push(a);
                    }

                    this.fileList = img_array2;
                    console.log("修改的路径：", this.fileList);
                }
            });
        },

        // 修改提交
        updateSubmit: function (data) {
            this.dialogFormUpdate = false;
            let uid = cookies.get("uid");
            let para = {
                id: this.form.id,
                title: this.form.title,
                content: this.form.content,
                type: this.form.type,
                user_id: uid,
                level: this.form.level,
                img_url: this.form.imgList.toString(),
            };
            updateWorkOrder(para).then((result) => {
                if (result.resultCode == 1) {
                    this.$message({
                        message: result.resultMsg,
                        type: "success",
                    });
                } else if (result.resultCode == -1001) {
                    this.$message({
                        message: result.resultMsg,
                        type: "error",
                    });
                }
            });
        },
        // 删除工单
        delWorkOrder: function (id) {
            this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                type: "warning",
            }).then(() => {
                let para = {
                    id: id,
                };
                delWorkOrder(para).then((result) => {
                    if (result.resultCode == 1) {
                        this.$message({
                            message: result.resultMsg,
                            type: "success",
                        });
                    } else if (result.resultCode == -1001) {
                        this.$message({
                            message: result.resultMsg,
                            type: "error",
                        });
                    }
                });
            });
        },
        // 跳转到详情页面
        toWorkOrderInfo: function (id) {
            this.$router.push({
                path: "/workOrder/workOrderInfo",
                query: { id: id },
            });
        },
        // 跳转页面
        to_mamage: function (p) {
            this.$router.push({
                path: "/workOrder/myManageWorkOrder",
                query: { type: p },
            });
        },
        // 级联选择器选值改变时触发的函数
        change: function (value) {
            // 选择器发生改变之前先把处理人清空
            this.form.handId = "";
            this.form.departId = value[value.length - 1];
            console.log("depart_id", this.form.departId);
            // 开始请求部门里的人员
            if (this.form.departId != null) {
                let para = {
                    dept_id: this.form.departId,
                };

                getAdminListByDeptId(para).then((resData) => {
                    if (resData.resultCode == 1) {
                        var res = resData.data;
                        var admins = [];
                        for (var i = 0; i < res.length; i++) {
                            var a = {};
                            a.value = res[i].id;
                            a.label = res[i].name;
                            admins.push(a);
                        }
                        this.adminList = admins;
                    } else {
                        this.$message({
                            message: resData.resultMsg,
                            type: "error",
                        });
                    }
                });
            }
        },
        //部门列表信息
        getDeptInfoList: function () {
            getDeptInfoList().then((resData) => {
                if (resData.resultCode == 1) {
                    let res = resData.data;
                    let farr = [];
                    let sarr = [];
                    let tarr = [];
                    if (res.length > 0) {
                        let resData = res;
                        for (var i = 0; i < resData.length; i++) {
                            if (!farr[resData[i].fId]) {
                                farr[resData[i].fId] = {
                                    label: resData[i].fDeptName,
                                    value: resData[i].fId,
                                };
                            }
                            if (!sarr[resData[i].sId]) {
                                sarr[resData[i].sId] = {
                                    label: resData[i].sDeptName,
                                    value: resData[i].sId,
                                    parentId: resData[i].sParentId,
                                };
                            }
                            if (!tarr[resData[i].tId]) {
                                tarr[resData[i].tId] = {
                                    label: resData[i].tDeptName,
                                    value: resData[i].tId,
                                    parentId: resData[i].tParentId,
                                };
                            }
                        }
                        for (var i = 0; i < tarr.length; i++) {
                            if (tarr[i]) {
                                if (!sarr[tarr[i].parentId].children) {
                                    sarr[tarr[i].parentId].children = [];
                                }
                                sarr[tarr[i].parentId].children.push(tarr[i]);
                            }
                        }
                        for (var i = 0; i < sarr.length; i++) {
                            if (sarr[i]) {
                                if (!farr[sarr[i].parentId].children) {
                                    farr[sarr[i].parentId].children = [];
                                }
                                farr[sarr[i].parentId].children.push(sarr[i]);
                            }
                        }
                        this.deptList = [];
                        for (var i = 0; i < farr.length; i++) {
                            if (farr[i]) {
                                this.deptList.push(farr[i]);
                            }
                        }
                    }
                } else {
                    this.$message({
                        message: resData.resultMsg,
                        type: "error",
                    });
                }
            });
        },

        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log(file);
        },
        // 上传成功
        handleImageSuccess(res, file, fileList) {
            // 上传成功
            // this.dialogImageUrl = fileList;
            var url = "http://" + res.data.url + "";
            console.log("上传成功" + fileList.length);
            console.log("返回图片路径：" + url);
            console.log("图片列表", this.form.imgList);
            this.form.imgList.push(url);
        },
        uploadError(res) {
            console.log("上传失败" + res);
        },
        handlePreview(file) {
            console.log(file);
        },

        // 重置
        resetForm() {
            this.filters = {};
        },
        // 标签页改变
        handleClick(tab, event) {
            this.to_mamage(this.activeName);
        },

        // 获取工单类型字典
        getorgTypeDic: function () {
            let para = {
                classifyCode: "workType",
            };
            getSysDictionaryList(para).then((result) => {
                this.org_type_list = result.data;
                console.log("工单类型字典列表", this.org_type_list);
            });
        },

        // 获取紧急级别字典
        getWorkLevelDic: function () {
            let para = {
                classifyCode: "workLevel",
            };
            getSysDictionaryList(para).then((result) => {
                this.work_level_list = result.data;
                console.log("紧急级别字典列表", this.work_level_list);
            });
        },

        // 获取工单状态字典
        getWorkStatusDic: function () {
            let para = {
                classifyCode: "workStatus",
            };
            getSysDictionaryList(para).then((result) => {
                this.work_status_list = result.data;
                console.log("字典列表", this.work_level_list);
            });
        },
    },
};
</script>

<style lang="scss">
.admin-index {
    .el-table thead {
        color: #21293b;
        font-size: 0.8rem;
    }
    .el-table th,
    .el-table thead {
        background-color: #e9ecee;
        height: 3rem;
    }
    .el-table {
        font-size: 0.8rem;
    }
}
</style>
